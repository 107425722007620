.coins {
  background-color: #0a0c15;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
}

.coins .div {
  background-color: #0a0c15;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.coins .overlap {
  /* height: 100%; */
  /* left: 0; */
  /* position: absolute; */
  /* top: 83px; */
  width: 100%;
}

.coins .rectangle {
  background-color: #0d0f18;
  border-radius: 39px;
  box-shadow: 0px -12px 0px #06070d;
  height: 100%;
  /* left: 0; */
  /* position: absolute; */
  /* top: 0; */
  width: 100%;
}

/* .coins .rectangle-2 {
  background-color: #13151e;
  height: 61px;
  left: 6px;
  position: absolute;
  top: 497px;
  width: 371px;
} */

/* .coins .text-wrapper {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 8px;
  font-weight: 400;
  left: 294px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 424px;
} */

.coins .fi {
  height: 39px;
  width: 39px;
}

/* .coins .text-wrapper-2 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 8px;
  font-weight: 400;
  left: 178px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 426px;
} */

/* .coins .text-wrapper-3 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 8px;
  font-weight: 400;
  left: 34px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 428px;
} */

.coins .text-wrapper-4 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 8px;
  font-weight: 400;
  left: 312px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  bottom: 0;
}

.coins .text-wrapper-5 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 8px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  /* top: 148px; */
}

.coins .text-wrapper-6 {
  color: #ffffff;
  font-family: "General Sans-Bold", Helvetica;
  font-size: 8px;
  font-weight: 700;
  left: 314px;
  letter-spacing: 0;
  line-height: normal;
  /* position: absolute; */
  top: 152px;
}

.coins .frame-wrapper {
  background-color: #13151e;
  border-radius: 40.45px;
  height: 38px;
  /* left: 33px;
  position: absolute;
  top: 11px; */
  width: 93px;
}

.coins .frames {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.35px;
  height: 27px;
  left: 15px;
  position: relative;
  top: 5px;
  width: 65px;
}

.coins .text-wrapper-7 {
  color: #ff8b3e;
  font-family: "GeneralSans", sans-serif;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -0.32px;
  margin-right: -0.32px;
  margin-top: -1.35px;
  position: relative;
  width: fit-content;
}

.coins .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.35px;
  margin-bottom: -0.38px;
  position: relative;
}

.coins .img {
  height: 1.1rem;
  width: 1.1rem;
}

.coins .text-wrapper-8 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.35px;
  position: relative;
  width: fit-content;
}

.coins .div-wrapper {
  background-color: #13151e;
  border-radius: 40.45px;
  height: 38px;
  /* left: 140px;
  position: absolute;
  top: 11px; */
  width: 93px;
}

.coins .frame-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.35px;
  height: 27px;
  left: 27px;
  position: relative;
  top: 5px;
  width: 38px;
}

.coins .text-wrapper-9 {
  color: #ff8b3e;
  font-family: "GeneralSans", sans-serif;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -0.12px;
  margin-right: -0.12px;
  margin-top: -1.35px;
  position: relative;
  width: fit-content;
}

.coins .group-2 {
  background-color: #13151e;
  border-radius: 40.45px;
  height: 38px;
  /* left: 242px;
  position: absolute;
  top: 11px; */
  width: 100px;
}

.coins .frame-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.35px;
  height: 27px;
  left: 27px;
  position: relative;
  top: 5px;
  width: 53px;
}

.coins .text-wrapper-10 {
  color: #ff8b3e;
  font-family: "GeneralSans", sans-serif;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.35px;
  position: relative;
  width: fit-content;
}

.coins .rectangle-3 {
  background-color: #13151e;
  border-radius: 44.17px;
  height: 32px;
  left: 117px;
  position: absolute;
  top: 453px;
  width: 141px;
}

.coins .rectangle-4 {
  background-color: #13151e;
  border-radius: 44.17px;
  height: 32px;
  left: 269px;
  position: absolute;
  top: 453px;
  width: 38px;
}

.coins .rectangle-5 {
  background-color: #13151e;
  border-radius: 44.17px;
  height: 32px;
  left: 70px;
  position: absolute;
  top: 453px;
  width: 38px;
}

.coins .text-wrapper-11 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 10.5px;
  font-weight: 400;
  left: 148px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 461px;
}

.coins .overlap-wrapper {
  /* height: 274px; */
  /* left: 51px; */
  /* position: absolute; */
  /* top: 112px; */
  width: 274px;
  margin: 0 auto;
}

.coins .overlap-group {
  /* background-image: url(https://c.animaapp.com/SG58ovQI/img/ellipse-4.svg); */
  background-size: 100% 100%;
  height: 68px;
  left: 23px;
  position: relative;
  top: -2px;
  width: 225px;
}

.coins .ellipse {
  height: 54px;
  left: 2px;
  position: absolute;
  top: 12px;
  width: 65px;
}

/* .coins .fi-2 {
  max-height: 15.5rem;
  height: 75%;
  position: absolute;
  width: auto;
  margin: 0 auto;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.coins .fi-2 {
  max-height: 15.5rem;
  height: 75%;
  position: absolute;
  width: auto;
  margin: 0 auto;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coins .frame-5 {
  align-items: center;
  display: flex;
  gap: 4.61px;
  justify-content: center;
}

.coins .fi-3 {
  height: 37.83px;
  position: relative;
  width: 37.83px;
}

.coins .text-wrapper-12 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 31.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.92px;
  position: relative;
  width: fit-content;
}

.coins .fi-4 {
  height: 21px;
  width: 21px;
}

.coins .fi-5 {
  height: 24px;
  width: 24px;
}

/* Action starts here */

/* .coins .action-6 {
  align-items: flex-start;
  display: inline-flex;
  gap: 1px;
  left: 14px;
  position: absolute;
  top: 512px;
} */

.coins .action-7 {
  align-items: flex-start;
  background-color: #0d0f18;
  border-radius: 52.62px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.77px;
  overflow: hidden;
  padding: 4.38px 21.92px;
  position: relative;
}

.coins .action-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.75px;
  position: relative;
}

.coins .action-8:hover,
.action-9:hover,
.action-11:hover {
  background-color: #0d0f18;
}

.coins .action-8:focus,
.action-9:focus,
.action-11:focus {
  background-color: #0d0f18;
}

.coins .fi-action-6 {
  height: 11.19px;
  position: relative;
  width: 11.19px;
}

.coins .text-action-wrapper-13 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.coins .action-9 {
  align-items: flex-start;
  border-radius: 52.62px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.77px;
  overflow: hidden;
  padding: 4.38px 21.92px;
  position: relative;
}

.coins .action-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.coins .fi-action-7 {
  height: 13.59px;
  position: relative;
  width: 13.59px;
}

.coins .text-action-wrapper-14 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.coins .fi-action-8 {
  height: 17.22px;
  position: relative;
  width: 17.22px;
}

.coins .text-action-wrapper-15 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.coins .action-11 {
  align-items: flex-start;
  border-radius: 52.62px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  overflow: hidden;
  padding: 4.38px 21.92px;
  position: relative;
}

.coins .fi-action-9 {
  height: 17.08px;
  position: relative;
  width: 17.08px;
}

.coins .text-action-wrapper-16 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3px;
  position: relative;
  width: fit-content;
}

.coins .fi-action-10 {
  height: 15.62px;
  position: relative;
  width: 15.62px;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.action img {
  transition: transform 0.3s ease-in-out;
}

.action-7:hover img,
.action-7:active img,
.action-9:hover img,
.action-9:active img,
.action-11:hover img,
.action-11:active img {
  animation: enlarge 0.5s ease-in-out, shake 0.5s ease-in-out 0.5s;
}

/* Action ends here */

.coins .expand-left,
.expand-left-instance {
  height: 24px !important;
  width: 24px !important;
}

.coins .expand-left-2 {
  height: 24px !important;
  width: 24px !important;
}

@keyframes buttonPressX {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.7);
  }
  100% {
    transform: scaleX(1);
  }
}

.expand-left:active,
.expand-left-2:active,
.expand-left-instance:active {
  animation: buttonPressX 0.3s forwards;
}

.coins .overlap-2 {
  height: 32px;
  left: 64px;
  position: absolute;
  top: 32px;
  width: 75px;
}

.coins .text-wrapper-17 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.coins .text-wrapper-18 {
  color: #f28a00;
  font-family: "GeneralSans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 17px;
}

.coins .ellipse-2 {
  height: 35px;
  left: 22px;
  position: absolute;
  top: 32px;
  width: 35px;
}

.coins .overlap-group-wrapper {
  height: 27px;
  /* left: 245px;
  position: absolute;
  top: 36px; */
  width: 104px;
}

.coins .overlap-group-2 {
  background-color: #1d202b;
  border-radius: 60px;
  position: relative;
  min-width: 102px;
}

.coins .text-wrapper-19 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
}

@keyframes enlargeBig {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
}

.fi-4:active,
.fi-5:active,
.fi:active {
  animation: enlargeBig 0.5s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colorChange {
  0% {
    color: #ffffff;
  }
  50% {
    color: #ffde00;
  }
  100% {
    color: #ffffff;
  }
}

.frame-5 {
  display: inline-block;
}

.frame-5.animating {
  animation: scaleUp 0.5s ease-in-out, colorChange 0.5s ease-in-out;
}

.glowing-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: transparent;
  box-shadow: 0 0 10px #ffde00, 0 0 20px #ffde00, 0 0 30px #ffde00,
    0 0 40px #ffde00;
  z-index: 97;
}

.score-animation {
  position: absolute;
  animation: floatUp var(--animation-duration, 3s) ease-in-out;
  color: #ffde00;
  font-size: 20px;
  font-weight: bold;
  z-index: 97;
  align-items: center;
  display: flex;
  gap: 1.39px;
}

@keyframes floatUp {
  0% {
    opacity: 1;
    top: initial; /* Start from the original position */
  }
  100% {
    opacity: 0;
    top: 10px; /* Adjust this value to control the stopping height */
  }
}

.text-wrapper-animation {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.22px;
  position: relative;
  width: fit-content;
}

@keyframes floatUp {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0.6;
  }
  85% {
    opacity: 0.2;
  }
  100% {
    top: 20%;
    opacity: 0;
  }
}

/* .coins .group {
  height: 100%;
  left: 0;
  right: 0;
  position: absolute;
  top: 0px;
  width: auto;
  margin: 0 auto;
} */

.coins .group {
  height: 100%;
  max-height: 22.75rem;
  left: 0;
  right: 0;
  /* position: absolute; */
  top: 0px;
  width: auto;
  margin: 0 auto;
}

.coins .groupAnimation {
  height: 100%;
  left: 0px;
  right: 0px;
  position: absolute;
  top: 0px;
  width: auto;
  transform-origin: center;
  opacity: 0;
  margin: 0 auto;
}

.coins .groupAnimation.animate {
  animation: expandAndShrink ease-in forwards;
}

@keyframes expandAndShrink {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.8);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.coin {
  width: 50px;
  height: 50px;
  background-color: gold;
  border-radius: 50%;
  position: absolute;
}
