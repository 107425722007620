.level-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    z-index: 99;
  }
  
  .level-up .div {
    background-color: #0d0f18;
    height: 353px;
    border: solid 3px #83c60d;
    border-radius:12px;
    position: relative;
    width: 290px;
  }
  
  .level-up .fi {
    height: 91px;
    left: 102px;
    position: absolute;
    top: 18px;
    width: 91px;
  }
  
  .level-up .text-wrapper {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    left: 99px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 156px;
  }
  
  .level-up .text-wrapper-2 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 19.1px;
    font-weight: 400;
    left: 109px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 128px;
  }
  
  .level-up .frame-2 {
    align-items: center;
    display: inline-flex;
    gap: 5.27px;
    left: 124px;
    position: absolute;
    top: 226px;
  }
  
  .level-up .img {
    height: 18.08px;
    position: relative;
    width: 18.08px;
  }
  
  .level-up .text-wrapper-3 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 14.5px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.78px;
    position: relative;
    width: fit-content;
  }
  
  .level-up .frame-3 {
    align-items: center;
    display: inline-flex;
    gap: 5.27px;
    left: 33px;
    position: absolute;
    top: 228px;
  }
  
  .level-up .frame-4 {
    align-items: center;
    display: inline-flex;
    gap: 5.27px;
    left: 206px;
    position: absolute;
    top: 226px;
  }
  
  .level-up .fi-2 {
    height: 15.82px;
    position: relative;
    width: 15.82px;
  }
  
  .level-up .frame-10 {
    border: 2.44px solid !important;
    border-radius: 122.08px !important;
    box-shadow: 0px 4.88px 0px #4f770b !important;
    gap: 12.21px !important;
    left: 33px !important;
    padding: 10.99px 76.91px !important;
    position: absolute !important;
    top: 273px !important;
    width: 225px !important;
  }
  
  .level-up .frame-instance {
    font-size: 18.3px !important;
    margin-top: -2.44px !important;
    color:black;
    font-weight: 500;
  }
  
  .level-up .text-wrapper-4 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 124px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 202px;
  }
  
  .level-up .text-wrapper-5 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 202px;
  }
  
  .level-up .text-wrapper-6 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 209px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 202px;
  }


  