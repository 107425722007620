.onboarding {
    background-color: #0d0f18;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow-x: scroll;
  }
  
  .onboarding .div {
    background-color: #0d0f18;
    position: relative;
    flex-direction: column;
    gap: 2rem;
    align-content: center;
    width: 21rem;
    margin: 40px auto 55px auto;
  }
  
  .onboarding .frame-2 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
    /* width: fit-content; */
  }
  
  .onboarding .text-wrapper-2 {
    color: #F1FFD9;
    font-family: "GeneralSans", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  
  .onboarding .p {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 0.937rem;
    /* font-weight: 200; */
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
  }
  
  .onboarding .frame-10 {
    left: 59px !important;
    position: absolute !important;
    top: 536px !important;
  }
  
  .onboarding .rectangle {
    background-color: #161a2a;
    /* border: 7px solid; */
    /* border-color: #77b702; */
    border-radius: 47.08px;
    box-shadow: 0px 4px 61px #82ca0070;
    height: 317px;
    position: relative;
    width: 310px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .onboarding .group {
    height: 3px;
    display: flex;
    justify-content: center;
  }
  
  .onboarding .frame-3 {
    align-items: center;
    display: inline-flex;
    gap: 4px;
    width: fit-content;
    margin: auto;
  }
  
  .onboarding .rectangle-2 {
    background-color: #83c60d;
    border-radius: 4px;
    height: 3px;
    position: relative;
    width: 14px;
  }
  
  .onboarding .rectangle-3 {
    background-color: #2b365a;
    border-radius: 4px;
    height: 3px;
    position: relative;
    width: 14px;
  }