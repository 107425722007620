 /* Action starts here */

 .actions .action-6 {
    align-items: flex-start;
    display: inline-flex;
    gap: 1px;
    left: 14px;
    position: absolute;
    top: 512px;
    background-color: #13151e;
    padding: 6px 4px;
  }
  
  .actions .action-7 {
    align-items: flex-start;
    background-color: #0d0f18;
    border-radius: 52.62px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8.77px;
    overflow: hidden;
    padding: 4.38px 21.92px;
    position: relative;
  }
  
  .actions .action-8 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 1.75px;
    position: relative;
  }
  
  .actions .action-8:hover, .action-9:hover, .action-11:hover {
    background-color: #0d0f18;
  }
  
  .actions .action-8:focus, .action-9:focus, .action-11:focus {
    background-color: #0d0f18;
  }
  
  .actions .fi-action-6 {
    height: 11.19px;
    position: relative;
    width: 11.19px;
  }
  
  .actions .text-action-wrapper-13 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 7.9px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .actions .action-9 {
    align-items: flex-start;
    border-radius: 52.62px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8.77px;
    overflow: hidden;
    padding: 4.38px 21.92px;
    position: relative;
  }
  
  .actions .action-10 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  .actions .fi-action-7 {
    height: 13.59px;
    position: relative;
    width: 13.59px;
  }
  
  .actions .text-action-wrapper-14 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 7.9px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .actions .fi-action-8 {
    height: 17.22px;
    position: relative;
    width: 17.22px;
  }
  
  .actions .text-action-wrapper-15 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 7.9px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -2px;
    position: relative;
    width: fit-content;
  }
  
  .actions .action-11 {
    align-items: flex-start;
    border-radius: 52.62px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 5px;
    overflow: hidden;
    padding: 4.38px 21.92px;
    position: relative;
  }
  
  .actions .fi-action-9 {
    height: 17.08px;
    position: relative;
    width: 17.08px;
  }
  
  .actions .text-action-wrapper-16 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 7.9px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -3px;
    position: relative;
    width: fit-content;
  }
  
  .actions .fi-action-10 {
    height: 15.62px;
    position: relative;
    width: 15.62px;
  }
  
  @keyframes enlarge {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
  }
  
  
  .action img {
    transition: transform 0.3s ease-in-out;
  }
  
  .action-7:hover img,
  .action-7:active img,
  .action-9:hover img,
  .action-9:active img,
  .action-11:hover img,
  .action-11:active img
   {
    animation: enlarge 0.5s ease-in-out, shake 0.5s ease-in-out 0.5s;
  } 
  
  /* Action ends here */