.upgrades {
    background-color: #0d0f18;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .div {
    background-color: #0d0f18;
    height: 640px;
    overflow: hidden;
    position: relative;
    width: 360px;
  }

  .upgradeFlex {
    background-color: #0d0f18;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }

  .overlap2, .overlap5 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    width: calc(50% - 20px); /* Adjust the width to be 50% minus the gap */
    margin-bottom: 20px; /* Space between rows */
    position: relative;
  }
  
  .textWrapper {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 59px;
  }
  
  .textWrapper2 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 9px;
    font-weight: 400;
    left: 33px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 85px;
  }
  
  .textWrapper3 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 152px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 108px;
  }
  
  .textWrapper4 {
    color: #ffce33;
    font-family: "GeneralSans", sans-serif;
    font-size: 9px;
    font-weight: 400;
    left: 268px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 72px;
  }
  
  .overlap {
    background-color: #83c60d;
    border-radius: 31px;
    height: 24px;
    left: 29px;
    position: absolute;
    top: 178px;
    width: 65px;
  }
  
  .textWrapper5 {
    color: #0d0f18;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 3px;
  }
  
  .overlapGroup {
    background-color: #0a0c15;
    border: 2px solid;
    border-color: #212942;
    border-radius: 31px;
    height: 24px;
    left: 105px;
    position: absolute;
    top: 176px;
    width: 65px;
  }
  
  .all {
    color: #0d0f18;
    font-family: "GeneralSans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .textWrapper6 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 1px;
  }
  
  .overlap2 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    height: 75px;
    left: 17px;
    position: absolute;
    top: 216px;
    width: 161px;
  }
  
  .overlap3 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px 7px 0px 0px;
    box-shadow: 0px 2px 0px #00000040;
    height: 46px;
    left: 0;
    position: absolute;
    top: 0;
    width: 161px;
  }
  
  .textWrapper7 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    left: 62px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .textWrapper8 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 6px;
    font-weight: 400;
    left: 63px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 17px;
  }
  
  .frame {
    align-items: center;
    display: inline-flex;
    gap: 4.18px;
    left: 61px;
    position: absolute;
    top: 28px;
  }
  
  .fi {
    height: 10.68px;
    mix-blend-mode: luminosity;
    position: relative;
    width: 10.68px;
  }
  
  .textWrapper9 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 9.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.13px;
    position: relative;
    width: fit-content;
  }
  
  .div2 {
    height: 37px;
    left: 1px;
    position: absolute;
    top: 4px;
    width: 46px;
  }
  
  .groupWrapper {
    background-color: #1b2034;
    border-radius: 7px;
    height: 37px;
    left: 4px;
    position: relative;
    width: 42px;
  }
  
  .group {
    height: 27px;
    left: 8px;
    position: absolute;
    top: 6px;
    width: 27px;
  }
  
  .textWrapper10 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 7px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 54px;
  }
  
  .frame2 {
    align-items: center;
    display: inline-flex;
    gap: 4.18px;
    left: 78px;
    position: absolute;
    top: 53px;
  }
  
  .img {
    height: 10.68px;
    position: relative;
    width: 10.68px;
  }
  
  .textWrapper11 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 9.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.13px;
    position: relative;
    width: fit-content;
  }
  
  .overlap4 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    height: 75px;
    left: 17px;
    position: absolute;
    top: 300px;
    width: 161px;
  }
  
  .frame3 {
    align-items: center;
    display: inline-flex;
    gap: 4.18px;
    left: 61px;
    position: absolute;
    top: 29px;
  }
  
  .overlap5 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    height: 75px;
    left: 187px;
    position: absolute;
    top: 216px;
    width: 161px;
  }
  
  .textWrapper12 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 6px;
    font-weight: 400;
    left: 63px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 16px;
  }
  
  .overlapWrapper {
    height: 37px;
    left: 0;
    position: absolute;
    top: 0;
    width: 46px;
  }
  
  .rectangle {
    background-color: #1b2034ad;
    border-radius: 7px;
    height: 37px;
    left: 4px;
    position: absolute;
    top: 0;
    width: 42px;
  }
  
  .fi2 {
    height: 21px;
    left: 16px;
    position: absolute;
    top: 7px;
    width: 21px;
  }
  
  .textWrapper13 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 7px;
    font-weight: 400;
    left: 88px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 53px;
  }
  
  .overlap6 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    height: 75px;
    left: 187px;
    position: absolute;
    top: 300px;
    width: 161px;
  }
  
  .frame4 {
    align-items: center;
    display: inline-flex;
    gap: 4.18px;
    left: 61px;
    position: absolute;
    top: 28px;
  }
  
  .group2 {
    height: 37px;
    left: 0;
    position: absolute;
    top: 0;
    width: 46px;
  }
  
  .overlapWrapper2 {
    background-color: #1b2034;
    border-radius: 7px;
    height: 37px;
    left: 4px;
    position: relative;
    width: 42px;
  }
  
  .group3 {
    height: 27px;
    left: 8px;
    position: absolute;
    top: 6px;
    width: 27px;
  }
  
  .textWrapper14 {
    color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 7px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 54px;
  }
  
  .textWrapper15 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 9.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.13px;
    position: relative;
    width: fit-content;
  }
  .frame6 {
    align-items: flex-end;
    display: inline-flex;
    gap: 3.31px;
    left: 132px;
    position: absolute;
    top: 127px;
  }

  .frame6 .fi3 {
    height: 27.16px;
    position: relative;
    width: 27.16px;
  }

  .frame6 .textWrapper15 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 22.4px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.66px;
    position: relative;
    width: fit-content;
  }

  .textWrapper14 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }

  .frame4 {
    align-items: center;
    display: inline-flex;
    gap: 160px;
    left: 25px;
    position: absolute;
    top: 13px;
  }
  
  .frame4 .frame5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .frame4 .arrowLeft {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .frame4 .textWrapper14 {
    color: #ffffff;
    font-family: "GeneralSans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
  }
  
  .frame4 .group2 {
    height: 12px;
    position: relative;
    width: 2.86px;
  }
  
  .frame4 .ellipse {
    background-color: #ffffff;
    border-radius: 1.43px;
    height: 3px;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
  }
  
  .frame4 .ellipse2 {
    background-color: #ffffff;
    border-radius: 1.43px;
    height: 3px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 3px;
  }
  
  .frame4 .ellipse3 {
    background-color: #ffffff;
    border-radius: 1.43px;
    height: 3px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 3px;
  }
  
  