.start {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%);
  background-color: transparent;
  z-index: 99;
}

.start .div {
  border-radius: 12px;
  background-color: #0d0f18;
  border: solid 3px #83c60d;
  min-height: 380px;
  position: relative;
  /* width: 290px; */
  text-align: center;
  align-items: center;
}

.start .fi {
  height: 119px;
  left: 85px;
  position: absolute;
  top: 14px;
  width: 119px;
}

.start .text-wrapper {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  left: 61px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 173px;
}

.start .text-wrapper-2 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 19.1px;
  font-weight: 400;
  left: 42px;
  letter-spacing: 0;
  text-align: center;
  line-height: normal;
  position: absolute;
  top: 142px;
}

.start .frame-2 {
  align-items: center;
  display: inline-flex;
  gap: 7px;
  left: 115px;
  position: absolute;
  top: 224px;
}

.start .img {
  height: 24px;
  position: relative;
  width: 24px;
}

.start .text-wrapper-3 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 19.2px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2.36px;
  position: relative;
  width: fit-content;
}

.start .frame-100 {
  border: 2.44px solid !important;
  border-radius: 122.08px !important;
  box-shadow: 0px 4.88px 0px #4f770b !important;
  gap: 12.21px !important;
  left: 33px !important;
  padding: 10.99px 76.91px !important;
  position: absolute !important;
  top: 273px !important;
  background-color: #83c60d;
  width: 225px !important;
}

.start .frame-instance {
  font-size: 18.2px;
  color: #000000;
  font-weight: 600;
}
