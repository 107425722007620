.boosters {
  background-color: #0d0f18;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  width: 100%;
}

.boosters .div {
  background-color: #0d0f18;
  /* height: 640px; */
  overflow: hidden;
  position: relative;
  /* width: 360px; */
  width: 100%;
}

.boosters .text-wrapper {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 59px;
}

.boosters .overlap {
  height: 596px;
  left: -6px;
  position: absolute;
  top: 83px;
  width: 377px;
}

.boosters .actions {
  align-items: flex-start;
  display: inline-flex;
  gap: 1px;
  left: 8px;
  position: relative;
  top: 15px;
}

.boosters .actions-wrapper {
  align-items: flex-start;
  border-radius: 52.62px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.77px;
  overflow: hidden;
  padding: 4.38px 21.92px;
  position: relative;
}

.boosters .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.75px;
  position: relative;
}

.boosters .fi {
  height: 11.19px;
  position: relative;
  width: 11.19px;
}

.boosters .text-wrapper-2 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.boosters .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.boosters .img {
  height: 13.59px;
  position: relative;
  width: 13.59px;
}

.boosters .fi-2 {
  height: 17.22px;
  position: relative;
  width: 17.22px;
}

.boosters .text-wrapper-3 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2px;
  position: relative;
  width: fit-content;
}

.boosters .div-wrapper {
  align-items: flex-start;
  border-radius: 52.62px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  overflow: hidden;
  padding: 4.38px 21.92px;
  position: relative;
}

.boosters .fi-3 {
  height: 17.08px;
  position: relative;
  width: 17.08px;
}

.boosters .text-wrapper-4 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 7.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -3px;
  position: relative;
  width: fit-content;
}

.boosters .fi-4 {
  height: 15.62px;
  position: relative;
  width: 15.62px;
}

.boosters .text-wrapper-5 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 9px;
  font-weight: 400;
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 85px;
}

.boosters .text-wrapper-6 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 10px;
  font-weight: 400;
  left: 150px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 114px;
}

.boosters .text-wrapper-7 {
  color: #ffce33;
  font-family: "GeneralSans", sans-serif;
  font-size: 9px;
  font-weight: 400;
  left: 268px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 72px;
}

.boosters .frame-4 {
  align-items: center;
  display: inline-flex;
  gap: 177px;
  left: 25px;
  position: absolute;
  top: 13px;
}

.boosters .frame-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.boosters .arrow-left {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.boosters .text-wrapper-8 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: fit-content;
}

.boosters .group {
  height: 12px;
  position: relative;
  width: 2.86px;
}

.boosters .ellipse {
  background-color: #ffffff;
  border-radius: 1.43px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.boosters .ellipse-2 {
  background-color: #ffffff;
  border-radius: 1.43px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 3px;
}

.boosters .ellipse-3 {
  background-color: #ffffff;
  border-radius: 1.43px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 3px;
}

.boosters .frame-6 {
  align-items: flex-end;
  display: inline-flex;
  gap: 3.31px;
  left: 130px;
  position: absolute;
  top: 133px;
}

.boosters .fi-5 {
  height: 27.16px;
  position: relative;
  width: 27.16px;
}

.boosters .text-wrapper-9 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 22.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.66px;
  position: relative;
  width: fit-content;
}

/* .boosters .overlap-group {
  background-color: #151827;
    border-radius: 7px;
    height: 75px;
    left: 14px;
    position: absolute;
    top: 207px;
    width: 332px;
} */

.boosters .text-wrapper-10 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
}

/* .boosters .text-wrapper-11 {
  color: #848aa0;
    font-family: "GeneralSans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 40px;
} */

.boosters .text-wrapper-12 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  left: 229px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 28px;
}

.boosters .fi-6 {
  height: 29px;
  width: 29px;
}

/* .boosters .overlap-2 {
    background-color: #151827;
    border: 2px solid;
    border-color: #141827;
    border-radius: 7px;
    height: 75px;
    left: 14px;
    position: absolute;
    top: 303px;
    width: 332px;
  } */

.boosters .text-wrapper-13 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
}

.boosters .text-wrapper-14 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  left: 110px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 39px;
}

.boosters .frame-7 {
  align-items: center;
  display: inline-flex;
  gap: 5.75px;
  left: 52px;
  position: absolute;
  top: 40px;
}

.boosters .fi-7 {
  height: 14.67px;
  position: relative;
  width: 14.67px;
}

.boosters .text-wrapper-15 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 12.6px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.boosters .expand-left {
  height: 24px !important;
  left: 289px !important;
  position: absolute !important;
  top: 17px !important;
  width: 24px !important;
}

.boosters .img-2 {
  height: 25px;
  /* left: 9px;
    position: absolute;
    top: 21px; */
  width: 32px;
}

.boosters .overlap-group-2 {
  background-color: #151827;
  border: 2px solid;
  border-color: #141827;
  border-radius: 7px;
  height: 75px;
  left: 14px;
  position: absolute;
  top: 386px;
  width: 332px;
}

.boosters .text-wrapper-16 {
  color: #ffffff;
  font-family: "GeneralSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  left: 55px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
}

.boosters .text-wrapper-17 {
  color: #848aa0;
  font-family: "GeneralSans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  left: 114px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 40px;
}

.boosters .frame-8 {
  align-items: center;
  display: inline-flex;
  gap: 5.75px;
  left: 56px;
  position: absolute;
  top: 41px;
}

.boosters .expand-left-instance {
  height: 24px !important;
  left: 290px !important;
  position: absolute !important;
  top: 21px !important;
  width: 24px !important;
}

.boosters .fi-8 {
  height: 39px;
  /* left: 7px;
  position: absolute;
  top: 13px; */
  width: 39px;
}

/* .boosters .line {
    height: 1px;
    left: 121px;
    object-fit: cover;
    position: absolute;
    top: 293px;
    width: 117px;
  } */
