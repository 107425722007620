@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Bold.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Bold.woff") format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Bold.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Bold.eot") format("eot");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-BoldItalic.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-BoldItalic.woff")
      format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-BoldItalic.ttf")
      format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-BoldItalic.eot")
      format("eot");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Semibold.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Semibold.woff")
      format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Semibold.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Semibold.eot") format("eot");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Medium.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Medium.woff") format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Medium.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Medium.eot") format("eot");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Regular.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Regular.woff")
      format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Regular.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Regular.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Italic.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Italic.woff") format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Italic.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Italic.eot") format("eot");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "GeneralSans";
  src: url("/src/assets/fonts/general-sans/GeneralSans-Light.woff2")
      format("woff2"),
    url("/src/assets/fonts/general-sans/GeneralSans-Light.woff") format("woff"),
    url("/src/assets/fonts/general-sans/GeneralSans-Light.ttf") format("ttf"),
    url("/src/assets/fonts/general-sans/GeneralSans-Light.eot") format("eot");
  font-weight: 300;
  font-style: normal;
}

html {
  font-size: 100%;
}

body {
  background: #0d0f18;
  color: white;
  font-family: "GeneralSans", sans-serif;
  font-weight: 600;
  user-select: none;
  -moz-user-select: none;
}

.modal-shadow {
  box-shadow: 0px -24px 60px 0px #83c60d;
}

.sheet-shadow {
  box-shadow: 0px -24px 60px 0px #83c60d;
}

.button-primary {
  align-items: center;
  background-color: #83c60d;
  border: 3px solid;
  border-color: #2f4b00;
  border-radius: 136.97px;
  box-shadow: 0px 5.48px 0px #4f770b;
  display: flex;
  gap: 13.7px;
  justify-content: center;
  overflow: hidden;
  padding: 12px 20px;
  position: relative;
  width: 250px;
  outline: 0;
}

.button-primary .sub-button {
  color: #000000;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  /* margin-top: -2.74px; */
  position: relative;
  text-align: center;
  width: fit-content;
}

.button-primary:active {
  animation: buttonScaleX 4s forwards;
  box-shadow: none;
}

.button-primary:disabled {
  background-color: #848aa0;
  border: none;
  box-shadow: none;
}
.button-primary:disabled .sub-button {
  color: #1f2334;
}

@keyframes buttonScaleX {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.05);
  }
  100% {
    transform: scaleX(1);
  }
}

.notification {
  transition: all 0.3s ease-in-out;
}

.notification-container {
  position: fixed;
  top: 10px;
  z-index: 1000;
  width: 100%;
  margin: 0 auto;
  padding: 0 0.5rem;
  max-width: 450px;
}

/* src/ProgressBar.css */
.progress-bar-container {
  width: 100%;
  background-color: #1d202b;
  border: 1px solid #24262d;
  border-radius: 25px;
  overflow: hidden;
}

.progress-bar {
  height: 1.125rem;
  background: linear-gradient(90deg, #ffdb6a 0%, #f28a00 50.85%, #83c60d 100%);
  border-radius: 25px;
  transition: width 0.25s ease-in-out;
}
.center-absolute-el {
  left: 50%;
  transform: translateX(-50%);
}

@keyframes zoomBackground {
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 200%;
  }
}

.zoom-background {
  animation: zoomBackground 10s infinite forwards;
}

.no-vertical-scroll {
  overflow-y: hidden;
}

@keyframes skeleton-loading {
  0% {
      background-color: hsl(200, 20%, 70%);
  }

  100% {
      background-color: hsl(200, 20%, 95%);
  }
}

.underline-active-tab {position: relative;}
.underline-active-tab::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  width: 100%;
  height: 4px;
  background-color: #83C60D;
  border-radius: 0px 0px 10px 10px ;
}

.dailyComboItemShadow {
  box-shadow: 0px 3px 0px 0px #F28A00;
}

.tab-inactive-state{
  border: 2px solid #212942;
}